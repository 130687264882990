import { UserRoles } from "../store/auth/auth.selector";

export const ACCESS_TOKEN_KEY = "accessToken";

export const getAccessToken = () => sessionStorage.getItem(ACCESS_TOKEN_KEY);

export const removeAccessToken = () => sessionStorage.removeItem(ACCESS_TOKEN_KEY);

export const setAccessToken = (accessToken: string) => sessionStorage.setItem(ACCESS_TOKEN_KEY, accessToken);

export const isPlainObject = (obj: any): boolean => {
    return Object.prototype.toString.call(obj) === "[object Object]";
};

export const handleOwnerPrivileges = (userRoles: string[]) =>
    userRoles.some((item) => item === UserRoles.OWNER || item === UserRoles.OWNER);
export const handleEditorPrivileges = (userRoles: string[]) => userRoles.some((item) => item === UserRoles.EDITOR);
export const handlePricePrivileges = (userRoles: string[]) => userRoles.some((item) => item === UserRoles.PRICE);

export const compareListItems = (firstItem: any, secondItem: any) => {
    if (firstItem < secondItem) {
        return -1;
    }

    if (firstItem > secondItem) {
        return 1;
    }

    return 0;
};
const returnNulIfNan = (price: string) => (price === "NaN" ? "0" : price);

export const setPrice = (price: string, workingPrice: string, ingredientPrice: string) => {
    if (price !== "0") {
        return returnNulIfNan(price);
    }

    if (workingPrice !== "0") {
        return returnNulIfNan(workingPrice);
    }

    if (ingredientPrice !== "0") {
        return returnNulIfNan(ingredientPrice);
    }

    return "0";
};
