import React from "react";
import styles from "./RecipeViewList.module.scss";
import { Link } from "react-router-dom";
import { Ingredient, RecipesProps } from "../../../api/recipesList";
import { useSelector } from "react-redux";
import { getRecipesListState } from "../../../store/RecipesList/RecipesList.selector";
import { useRecipeViewList } from "./useRecipeViewList";
import { useIsInMobileView } from "../../../hooks/useIsInMobileView";

interface RecipeViewListProps {
    selectedRecipe: RecipesProps;
    isMainRecipe: boolean;
    isWorkingRecipe: boolean;
    sum: number;
    onSubmit: any;
    register: any;
    handleSumChange(value: string): void;
}

const RecipeViewList: React.FC<RecipeViewListProps> = ({
    sum,
    selectedRecipe,
    register,
    onSubmit,
    isMainRecipe,
    isWorkingRecipe,
    handleSumChange,
}) => {
    const { recipesList } = useSelector(getRecipesListState);
    const { isMas, handleSetMas } = useRecipeViewList();
    const { isMobile } = useIsInMobileView();

    const percentageSum = () => {
        const value = selectedRecipe.ingredients.reduce((a, b) => {
            const numberValue = b.value === "" ? 0 : parseFloat((b.value as string).replace(/,/g, ""));
            return a + numberValue;
        }, 0);

        return isNaN(value) ? 0 : value;
    };

    const calculateItemMas = (item: Ingredient) => {
        const mas = ((item.value as number) * sum) / 100;
        return isMas ? mas.toFixed(3) : (mas / parseFloat(item.density || "1")).toFixed(3);
    };

    return (
        <React.Fragment>
            <ul className={styles.recipeList}>
                <li className={styles.recipeListFirst}>
                    <div className={styles.recipeListItem}>
                        <div className={styles.recipeListItemPosition}>lp.</div>
                        <div className={styles.recipeListItemName}>SKŁADNIK</div>
                        <div className={styles.recipeListItemValue}> %</div>
                        <div className={`${styles.recipeListItemMas} ${styles.recipeListItemMasButton}`}>
                            {!isMobile && (
                                <button onClick={handleSetMas(isMas)} className="btn btn--black btn--height-medium">
                                    {isMas ? "Waga kg" : "Objętość l"}
                                </button>
                            )}
                            <span className={styles.recipeListItemMasPrint}>{"Masa"}</span>
                        </div>
                        {!isMobile && <div className={styles.recipeListItemDensity}>Gęstość</div>}
                        {!isMobile && <div className={styles.recipeListItemAdd}>Zadozowano</div>}
                        {!isMobile && <div className={styles.recipeListItemCorrect}>Korekta</div>}
                    </div>
                </li>
                {selectedRecipe.ingredients.map((item, index) => {
                    const hasProductionRecipe = recipesList.some((recipe: any) => recipe.recipeNumber === item.name);

                    return (
                        <li key={index} className={styles.recipeOdd}>
                            <>
                                <div className={styles.recipeListItem}>
                                    <div className={styles.recipeListItemPosition}>{index + 1}.</div>
                                    <div className={styles.recipeListItemName}>
                                        {hasProductionRecipe ? (
                                            <Link to={`/receptura/${item.name}`}>{item.name}</Link>
                                        ) : (
                                            item.name
                                        )}
                                    </div>
                                    <div className={styles.recipeListItemValue}>{item.value}</div>
                                    <div className={styles.recipeListItemMas}>{calculateItemMas(item)}</div>
                                    {!isMobile && (
                                        <div className={styles.recipeListItemDensity}>
                                            {item.density !== "NULL" && item.density}
                                        </div>
                                    )}
                                    {!isMobile && <div className={styles.recipeListItemAdd} />}
                                    {!isMobile && <div className={styles.recipeListItemCorrect} />}
                                </div>
                                {item.description && (
                                    <div
                                        className={
                                            item.description === "GAP"
                                                ? styles.recipeListItemEmptyDescription
                                                : styles.recipeListItemDescription
                                        }>
                                        {item.description === "GAP" ? "" : item.description}
                                    </div>
                                )}
                            </>
                        </li>
                    );
                })}
            </ul>
            <div className={styles.recipeListItem}>
                <div className={styles.recipeListItemPosition} />
                <div className={styles.recipeListItemName} />
                <div className={styles.recipeListItemValue}>{`${percentageSum().toFixed(2)} %`}</div>
                <div className={styles.recipeListItemSum}>
                    {isMainRecipe || isWorkingRecipe ? (
                        <>
                            <form onSubmit={onSubmit()} autoComplete="off" id="form" className={styles.recipeInput}>
                                <input
                                    name="recipeSum"
                                    placeholder="podaj masę"
                                    type="number"
                                    form="form"
                                    ref={register}
                                    value={sum || ""}
                                    onChange={(event) => handleSumChange(event.target.value)}
                                />
                            </form>
                            <p className={styles.recipeSumPrint}>{sum}</p>
                        </>
                    ) : (
                        <span>{sum}</span>
                    )}
                </div>
                {!isMobile && <div className={styles.recipeListItemDensity} />}
                {!isMobile && <div className={styles.recipeListItemAdd} />}
                {!isMobile && <div className={styles.recipeListItemCorrect} />}
            </div>
        </React.Fragment>
    );
};

export default RecipeViewList;
